import Link from 'next/link'
import { getCurrentPaginationPageFromLocation } from '../../utils/firmPageUtils';
import { useRouter } from 'next/router';
import ChevronLeft from '../vectors/ChevronLeft';
import ChevronRight from '../vectors/ChevronRight';
import { paginate } from '../../utils/utilities';

const PaginationItem = ({ url, isEnabled = true, isActive, children }) => {
    if (isActive) {
        return (
            <div className="relative no-underline z-10 rounded-full inline-flex items-center bg-secondary-blue px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline-offset-0 focus-visible:outline-secondary-blue">
                {children}
            </div>
        )
    }

    if (!isEnabled) {
        return (
            <div className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-300 cursor-not-allowed focus:z-20 focus:outline-offset-0`}>
                {children}
            </div>
        )
    }

    return (
        <Link
            href={url}
            passHref
        >
            <a className="relative no-underline inline-flex items-center rounded-r-md px-2 py-2 font-bold text-primary-blue hover:text-secondary-blue focus:z-20 focus:outline-offset-0">
                {children}
            </a>
        </Link>
    )
}

const PaginationItems = ({ structure, baseUrl, currentPage }) => {
    return structure.map((pageNumber, i) => {
        if (pageNumber === -1) {
            return (
                <span key={`pagination-${pageNumber}-elipsis-${i+1}`} className="relative no-underline inline-flex items-center px-4 py-2 font-semibold text-primary-blue focus:outline-offset-0">
                ...
                </span>
            )
        }

        return (
            <PaginationItem key={`pagination-${pageNumber}`} url={`${baseUrl}?page=${pageNumber}`} isActive={currentPage === pageNumber}>
                {pageNumber}
            </PaginationItem>
        )
    })
}

const ReviewPagination = ({ baseUrl, pagesCount }) => {
    const router = useRouter()
    const currentPage = getCurrentPaginationPageFromLocation(router);

    const paginationStructure = paginate(pagesCount, currentPage)

    return (
        <div className="flex items-center justify-center bg-white px-4 py-3 sm:px-6">
            <nav aria-label="Review pagination" className="isolate inline-flex -space-x-px rounded-md shadow-xs gap-1.5">
                <PaginationItem url={`${baseUrl}?page=${currentPage-1}`} isEnabled={currentPage > 1}>
                    <ChevronLeft />
                </PaginationItem>

                <PaginationItems structure={paginationStructure} baseUrl={baseUrl} currentPage={currentPage} />

                <PaginationItem url={`${baseUrl}?page=${currentPage + 1}`} isEnabled={currentPage < pagesCount}>
                    <ChevronRight />
                </PaginationItem>
            </nav>
        </div>
    )
};

export default ReviewPagination;
